<template>
  <div class="row m-0 h-100">
    <div class="col-sm-6">
      <div class="row h-100">
        <div class="col-12 mb-3">
          <card icon="icon" heading="Set up a Recurring Payment" @click="setRecurring" class="cursor" v-if="isUK">
            <IconCalendarFull color="gold" size="size28" />
          </card>
          <card icon="icon" heading="Set up a Recurring Donation" @click="setRecurring" class="cursor" v-else>
            <IconCalendarFull color="gold" size="size28" />
          </card>
        </div>
        <div class="col-12 mb-3">
          <card icon="icon" heading="Set up Daily Sadaqah" @click="goTo('dailysadaqah')" class="cursor" >
            <IconRecurring color="gold" size="size28" />
          </card>
        </div>
      </div>
    </div>
    <div class="col-sm-6 mb-3">
      <card icon="icon" heading="Ways to Donate" @click="goTo('waystodonate')" class="cursor" >
        <IconWays color="gold" size="size28" />
      </card>
    </div>
    <SetupRecurringDonationsUK v-if="isRecurring && isUK" @close="isRecurring = false"/>
    <SetupRecurringDonations v-else-if="isRecurring" @close="isRecurring = false" />
  </div>
</template>
<script>
import { defineAsyncComponent } from 'vue'

export default {
  components: {
    Card: defineAsyncComponent(() => import('@/views/Card.vue')),
    IconCalendarFull: defineAsyncComponent(() => import('@/components/icons/IconCalendarFull.vue')),
    IconRecurring: defineAsyncComponent(() => import('@/components/icons/IconRecurring.vue')),
    IconWays: defineAsyncComponent(() => import('@/components/icons/IconWays.vue')),
    SetupRecurringDonations: defineAsyncComponent(() => import('@/views/recurringDonations/SetupRecurringDonations.vue')),
    SetupRecurringDonationsUK: defineAsyncComponent(() => import('@/views/recurringDonations/uk/SetupRecurringDonations.vue'))

  },
  name: 'QuickDonateLinks',
  data () {
    return {
      isRecurring: false
    }
  },
  computed: {
    isUK () {
      if (process.env.VUE_APP_COUNTRY === 'UK') {
        return true
      } else {
        return false
      }
    }
  },
  methods: {
    goTo (val) {
      console.log('HERE', val)
      window.scrollTo(0, 0)
      this.$router.push('/' + val)
    },
    setRecurring () {
      this.isRecurring = true
    }
  }
}
</script>
